import React from 'react'
import Stripe from '../stripe/index'

import { withRouter } from "react-router"

import GeneralDisclaimer from '../components/GeneralDisclaimer'

import logo from '../assets/synkd_logo.png'
import { getMe } from '../api/calls'

const queryString = require('query-string')

class CompleteTransaction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            /** These are all populated from redirects from other page components (e.g Topup) */
            companyId: null,
            card: null,
            intentClientSecret: null,
            serviceName:'',
            /** --- */
            error: null,
            success: false,
            requiresAction: false,
            actionUrl: '',
        }
        this.stripe = new Stripe()
        
        this.showStatusText = this.showStatusText.bind(this)
    }

    on3DSComplete = async() => {
        const parsedQuery = queryString.parse(this.props.location.search)
        console.log("location.search: ", this.props.location.search)
        console.log("parseQuery: ", parsedQuery)

        // Check the PaymentIntent
        this.stripe._stripe.retrievePaymentIntent(this.state.intentClientSecret)
          .then(async(result) => {
            if (result.error) {
              // PaymentIntent client secret was invalid
              this.setState({
                error: result.error.message,
                requiresAction: false
            })
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show your customer that the payment has succeeded
                this.setState({
                    success: true,
                    requiresAction: false
                })
            
              } else if (result.paymentIntent.status === 'requires_payment_method') {
                // Authentication failed, prompt the customer to enter another payment method
                this.setState({
                    error: null,
                    success: false,
                    requiresAction: false
                })
              } else {
                this.setState({
                    error: null,
                    success: false,
                    requiresAction: false
                })
              }
            }
            console.log("callback: ",parsedQuery['callback'])
            if (parsedQuery['callback']) {
                // Redirect to the callback URL
                console.log("Redirect to the callback URL")
                await new Promise(resolve => setTimeout(resolve, 1000 * 3)) // timeout for 3 sec
                window.location.replace(parsedQuery['callback'])
            }
          });
      }

    async componentDidMount() {
        await this.stripe.loadStripe()
        // this.props.location.state comes from react-router redirects from other components
        const { companyId, card, intentClientSecret, serviceName } = this.props.location.state

        this.setState({
            companyId,
            card,
            intentClientSecret,
            serviceName
        })

        window.addEventListener('message', (ev) => {
            if (ev.data === '3DS-authentication-complete') {

              this.on3DSComplete()
            }
        }, false);

        // Confirm the card payment using the intent client secret
        let stripeRes = await this.stripe._stripe.confirmCardPayment(intentClientSecret,
              {
                //payment_method: {card: card},
                return_url: `${window.origin}/3DS-authentication-complete`
              },
              // Disable the default next action handling.
              {handleActions: false}
            )
        
        
        // stripeRes.paymentIntent for success, stripeRes.error for errors
        if (stripeRes.error) {
            this.setState({
                error: stripeRes.error.message,
                success: false
            })
        } else {

            let paymentIntent = stripeRes.paymentIntent

            if (
                typeof paymentIntent === 'object' &&
                (paymentIntent.status === 'requires_source_action' ||
                  paymentIntent.status === 'requires_action') &&
                paymentIntent.next_action &&
                paymentIntent.client_secret
              ) {

                let action = paymentIntent.next_action;

                if (action && action.type === 'redirect_to_url') {
                    this.setState({
                        requiresAction: true,
                        actionUrl: action.redirect_to_url.url
                    })
                    //window.location.replace(action.redirect_to_url.url);
                }
              }

        }
    }


    

    showStatusText() {
        if(this.state.requiresAction){
            return (
                <iframe
                    className="iframe"
                    title="3DS verification"
                    src={this.state.actionUrl}
                    width={600}
                    height={400}
                />
            )
        }

        if (this.state.error === null && this.state.success === false) {
            // Processing...
            return (
                <>
                    <h3 style={{marginTop: '10px'}}>Processing transaction</h3>
                    <p>We're processing your payment using your selected payment method now.</p>
                    <p>Please do not close this page.</p>
                </>
            )
        } else if (this.state.error !== null) {
            // Error
            return (
                <>
                    <h3 style={{marginTop: '10px'}}>Oops...</h3>
                    <p>We encountered an issue processing your transaction. Please try again later.</p>
                </>
            )
        
        } else if (this.state.success === true) {
            // Success
            return (
                <>
                    <h3 style={{marginTop: '10px'}}>Thanks!</h3>
                    <p>We've processed your payment successfully.<br />It may take a few minutes for your account to be updated.</p>
                    <p>We will return you to the page you were on in a few seconds.</p>
                </>
            )
        }
    }

    render() {
        return (
            <div className='main-container'>
                <img src={logo} style={{marginBottom: '1em'}} width='100px' className="App-logo" alt="logo" />
                <div className='content-container animate__animated animate__fadeIn'>
                    {this.showStatusText()}
                </div>
                <GeneralDisclaimer />
            </div>
        )
    }
}

export default withRouter(CompleteTransaction)
